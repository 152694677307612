@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

/* Variable fonts usage: */
:root {
  font-family: "DM Sans", sans-serif;
  font-family: "Duplicate Sans", sans-serif;
}
@supports (font-variation-settings: normal) {
  :root {
    font-family: "DM Sans", sans-serif;
    font-family: "Duplicate Sans", sans-serif;
    font-optical-sizing: auto;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-family: "DM Sans", sans-serif;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("./assets/fonts/DuplicateSans-Light.eot");
  src: local("Duplicate Sans Light"), local("DuplicateSans-Light"),
    url("./assets/fonts/DuplicateSans-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/DuplicateSans-Light.woff2") format("woff2"),
    url("./assets/fonts/DuplicateSans-Light.woff") format("woff"),
    url("./assets/fonts/DuplicateSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("./assets/fonts/DuplicateSans-ThinItalic.eot");
  src: local("Duplicate Sans Thin Italic"), local("DuplicateSans-ThinItalic"),
    url("./assets/fonts/DuplicateSans-ThinItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/DuplicateSans-ThinItalic.woff2") format("woff2"),
    url("./assets/fonts/DuplicateSans-ThinItalic.woff") format("woff"),
    url("./assets/fonts/DuplicateSans-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("./assets/fonts/DuplicateSans-BoldItalic.eot");
  src: local("Duplicate Sans Bold Italic"), local("DuplicateSans-BoldItalic"),
    url("./assets/fonts/DuplicateSans-BoldItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/DuplicateSans-BoldItalic.woff2") format("woff2"),
    url("./assets/fonts/DuplicateSans-BoldItalic.woff") format("woff"),
    url("./assets/fonts/DuplicateSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("./assets/fonts/DuplicateSans.eot");
  src: local("Duplicate Sans"), local("DuplicateSans"),
    url("./assets/fonts/DuplicateSans.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/DuplicateSans.woff2") format("woff2"),
    url("./assets/fonts/DuplicateSans.woff") format("woff"),
    url("./assets/fonts/DuplicateSans.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("./assets/fonts/DuplicateSans-Medium.eot");
  src: local("Duplicate Sans Medium"), local("DuplicateSans-Medium"),
    url("./assets/fonts/DuplicateSans-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/DuplicateSans-Medium.woff2") format("woff2"),
    url("./assets/fonts/DuplicateSans-Medium.woff") format("woff"),
    url("./assets/fonts/DuplicateSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("./assets/fonts/DuplicateSans-Italic.eot");
  src: local("Duplicate Sans Italic"), local("DuplicateSans-Italic"),
    url("./assets/fonts/DuplicateSans-Italic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/DuplicateSans-Italic.woff2") format("woff2"),
    url("./assets/fonts/DuplicateSans-Italic.woff") format("woff"),
    url("./assets/fonts/DuplicateSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("./assets/fonts/DuplicateSans-HeavyItalic.eot");
  src: local("Duplicate Sans Heavy Italic"), local("DuplicateSans-HeavyItalic"),
    url("./assets/fonts/DuplicateSans-HeavyItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/DuplicateSans-HeavyItalic.woff2") format("woff2"),
    url("./assets/fonts/DuplicateSans-HeavyItalic.woff") format("woff"),
    url("./assets/fonts/DuplicateSans-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("./assets/fonts/DuplicateSans-Thin.eot");
  src: local("Duplicate Sans Thin"), local("DuplicateSans-Thin"),
    url("./assets/fonts/DuplicateSans-Thin.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/DuplicateSans-Thin.woff2") format("woff2"),
    url("./assets/fonts/DuplicateSans-Thin.woff") format("woff"),
    url("./assets/fonts/DuplicateSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("./assets/fonts/DuplicateSans-Heavy.eot");
  src: local("Duplicate Sans Heavy"), local("DuplicateSans-Heavy"),
    url("./assets/fonts/DuplicateSans-Heavy.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/DuplicateSans-Heavy.woff2") format("woff2"),
    url("./assets/fonts/DuplicateSans-Heavy.woff") format("woff"),
    url("./assets/fonts/DuplicateSans-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("./assets/fonts/DuplicateSans-Bold.eot");
  src: local("Duplicate Sans Bold"), local("DuplicateSans-Bold"),
    url("./assets/fonts/DuplicateSans-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/DuplicateSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/DuplicateSans-Bold.woff") format("woff"),
    url("./assets/fonts/DuplicateSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("./assets/fonts/DuplicateSans-LightItalic.eot");
  src: local("Duplicate Sans Light Italic"), local("DuplicateSans-LightItalic"),
    url("./assets/fonts/DuplicateSans-LightItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/DuplicateSans-LightItalic.woff2") format("woff2"),
    url("./assets/fonts/DuplicateSans-LightItalic.woff") format("woff"),
    url("./assets/fonts/DuplicateSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Duplicate Sans";
  src: url("./assets/fonts/DuplicateSans-MediumItalic.eot");
  src: local("Duplicate Sans Medium Italic"),
    local("DuplicateSans-MediumItalic"),
    url("./assets/fonts/DuplicateSans-MediumItalic.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/DuplicateSans-MediumItalic.woff2") format("woff2"),
    url("./assets/fonts/DuplicateSans-MediumItalic.woff") format("woff"),
    url("./assets/fonts/DuplicateSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "DM Sans";
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  font-named-instance: "Italic";
  src: url(./assets/fonts/dm-san/DMSans-Italic.ttf);
}

@font-face {
  font-family: "DM Sans";
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  font-named-instance: "Normal";
  src: url(./assets/fonts/dm-san/DMSans-Regular.ttf);
}

.gradient__bg {
  /* ff 3.6+ */
  background: -moz-linear-gradient(
    254.23deg,
    #f7f5ff 2.17%,
    #ffffff 29.85%,
    #fff9f4 45.52%,
    #f9f9f9 69.19%,
    #ffffff 100.3%
  );

  /* safari 5.1+,chrome 10+ */
  background: -webkit-linear-gradient(
    254.23deg,
    #f7f5ff 2.17%,
    #ffffff 29.85%,
    #fff9f4 45.52%,
    #f9f9f9 69.19%,
    #ffffff 100.3%
  );

  /* opera 11.10+ */
  background: -o-linear-gradient(
    254.23deg,
    #f7f5ff 2.17%,
    #ffffff 29.85%,
    #fff9f4 45.52%,
    #f9f9f9 69.19%,
    #ffffff 100.3%
  );

  /* ie 10+ */
  background: -ms-linear-gradient(
    254.23deg,
    #f7f5ff 2.17%,
    #ffffff 29.85%,
    #fff9f4 45.52%,
    #f9f9f9 69.19%,
    #ffffff 100.3%
  );

  /* global 92%+ browsers support */
  background: linear-gradient(
    254.23deg,
    #f7f5ff 2.17%,
    #ffffff 29.85%,
    #fff9f4 45.52%,
    #f9f9f9 69.19%,
    #ffffff 100.3%
  );
  background-blend-mode: overlay;
}

[type="checkbox"]:checked {
  background-image: url('data:image/svg+xml,<svg viewBox="0 0 16 16" fill="rgba(5, 96, 58, 1)" xmlns="http://www.w3.org/2000/svg"><path d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"/></svg>');
}

.not-select {
  cursor: not-allowed;
}

.selector {
  outline: none !important;
  border-width: 0;
}

.selector:focus-visible {
  outline: none !important;
  border: none;
  box-shadow: none;
}

.PhoneInputInput {
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-left: 1px solid #e5e5e5;
  padding-left: 20px;
}

.PhoneInputInput:focus {
  outline: none !important;
}

.phone input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-left: 1px solid #e5e5e5;
}
